
export default {
    getFieldFilter(col) {
        if (col.filter){
            if (col.filter.key_filter_custom){
                return col.filter.key_filter_custom;
            }
        }
        return col.key;
    }
}