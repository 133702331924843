export const helpers = {
    escapeSpecialUrlCharacters(string) {
        return string
            .replace(/'/g, "%27")
            .replace(/&/g, "%26")
            .replace(/%/g, "%25")
            .replace(/#/g, "%23")
            .replace(/\s/g, "%20");
    },
    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}