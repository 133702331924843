<template>
  <div :id="idName" @click="generate">
    <slot> Download {{ name }} </slot>
  </div>
</template>

<script>
import download from "downloadjs";
// import gql from "graphql-tag";

export default {
  props: {
    // mime type [xls, csv]
    type: {
      type: String,
      default: "xls",
    },
    // Json to download
    data: {
      type: null,
      required: false,
      default: null,
      custom_field: null
    },
    // Custom export template function which return a html string that will override the default template
    renderHeader: {
      type: Function
    },
    renderBody: {
      type: Function
    },
    // fields inside the Json Object that you want to export
    // if no given, all the properties in the Json are exported
    fields: {
      type: null,
      default: () => null,
    },
    fieldsFunction: {
      type: Function,
    },
    exportName: {
      type: String,
      default: () => '',
    },
    // this prop is used to fix the problem with other components that use the
    // variable fields, like vee-validate. exportFields works exactly like fields
    exportFields: {
      type: Object,
      default: () => null,
    },
    // Use as fallback when the row has no field values
    defaultValue: {
      type: String,
      required: false,
      default: "",
    },
    // Companyname on first cell
    sheetInfo: {
      default: null,
    },
    // Title(s) for the data, could be a string or an array of strings (multiple titles)
    header: {
      default: null,
    },
    // sub Title, smaller than title
    subTitle: {
      default: null
    }, 

    // Style for column header
    colHeaderStyle: {
      default: null,
    },
    // Footer(s) for the data, could be a string or an array of strings (multiple footers)
    footer: {
      default: null,
    },
    // filename to export
    name: {
      type: String,
      default: "data.xls",
    },
    fetch: {
      type: Function,
    },
    filters: {
      type: null,
    },
    meta: {
      type: Array,
      default: () => [],
    },
    worksheet: {
      type: String,
      default: "Sheet1",
    },
    //event before generate was called
    beforeGenerate: {
      type: Function,
    },
    //event before download pops up
    beforeFinish: {
      type: Function,
    },
    // Determine if CSV Data should be escaped
    escapeCsv: {
      type: Boolean,
      default: true,
    },
    // long number stringify
    stringifyLongNum: {
      type: Boolean,
      default: false,
    },
    ShowsubTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // unique identifier
    idName() {
      var now = new Date().getTime();
      return "export_" + now;
    },

    downloadFields() {
      if (this.custom_field) return this.custom_field;

      if (this.fields) return this.fields;

      if (this.exportFields) return this.exportFields;

      return undefined;
    },
  },
  methods: {
    async generate() {
      if (typeof this.beforeGenerate === "function") {
        await this.beforeGenerate();
      }
      console.log("EXPORT EXCEL DATA", this.data, this.header)
      let tdata = this.data;
      if (typeof this.fetch === "function" || !this.data) tdata = await this.fetch(this.data, this.filters);

      if (!tdata || !tdata.length) {
        return;
      }
      if (typeof this.fields === 'function') {
        this.custom_field = await this.fields(tdata)
        console.log('this.custom_field',this.custom_field)
      }

      let json = this.getProcessedJson(tdata, this.downloadFields);
      if (this.type === "html") {
        // this is mainly for testing
        return this.export(
          this.jsonToXLS(json),
          this.name.replace(".xls", ".html"),
          "text/html"
        );
      } else if (this.type === "csv") {
        return this.export(
          this.jsonToCSV(json),
          this.name.replace(".xls", ".csv"),
          "application/csv"
        );
      }

      return this.export(
        this.jsonToXLS(json),
        this.name,
        "application/vnd.ms-excel"
      );
    },
    /*
		Use downloadjs to generate the download link
		*/
    export: async function (data, filename, mime) {
      let blob = this.base64ToBlob(data, mime);
      if (typeof this.beforeFinish === "function") await this.beforeFinish();
      download(blob, filename, mime);
    },
    /*
		jsonToXLS
		---------------
		Transform json data into an xml document with MS Excel format, sadly
		it shows a prompt when it opens, that is a default behavior for
		Microsoft office and cannot be avoided. It's recommended to use CSV format instead.
		*/
    jsonToXLS(data) {
      let xlsData = "<thead>";
      const colspan = Object.keys(data[0]).length;
      let _self = this;

      //Header
      const header = this.header || this.$attrs.title;
      
      if (this.sheetInfo) {
        xlsData += this.parseExtraData(
          this.sheetInfo,
          '<tr><th colspan="3">${data}</th></tr><tr></tr>'
        );
      }
      if (header) {
        xlsData += this.parseExtraData(
          header,
          '<tr><th colspan="' + colspan + '" style="font-size:25px">${data}</th></tr>'
        );
      }
      if (this.ShowsubTitle){
        if(this.subTitle ){
          let subTitleValue = this.subTitle;
          // console.log("SUBTITLE", this.subTitle)
          if(this.$_.isFunction(this.subTitle)) {
    
            subTitleValue = this.subTitle(data, this.filters);
          }
          xlsData += this.parseExtraData(
            subTitleValue,
            '<tr><th colspan="' + colspan + '" style="font-size:14px">${data}</th></tr>'
          );
        }
        xlsData += this.parseExtraData(
            "",
            '<tr><th colspan="' + colspan + '"></th></tr>'
          );
      }

      //Fields

      if(this.renderHeader) {
        xlsData += this.renderHeader(data, this.filters);
      } else {
        xlsData += "<tr>";
        for (let key in data[0]) {
          if(this.colHeaderStyle) {
            xlsData += `<th style="${this.colHeaderStyle}">`;
          } else {
            xlsData += "<th>";
          }
          xlsData += key + "</th>";
        }
        xlsData += "</tr>";
      }
      
      xlsData += "</thead>";

      //Data
      xlsData += "<tbody>";

      // RenderBody function when provided
      if(this.renderBody) {
        xlsData += this.renderBody(data, this.filters);
      } else {
        data.map(function (item) {
          xlsData += "<tr>";
          for (let key in item) {
            xlsData +=
              "<td>" +
              _self.preprocessLongNum(
                _self.valueReformattedForMultilines(item[key])
              ) +
              "</td>";
          }
          xlsData += "</tr>";
        });
      }
      
      xlsData += "</tbody>";

      //Footer
      if (this.footer != null) {
        xlsData += "<tfoot>";
        xlsData += this.parseExtraData(
          this.footer,
          '<tr><td colspan="' + colspan + '">${data}</td></tr>'
        );
        xlsData += "</tfoot>";
      }

      let xlsTemp =
        `<html 
          xmlns:o="urn:schemas-microsoft-com:office:office" 
          xmlns:x="urn:schemas-microsoft-com:office:excel" 
          xmlns="http://www.w3.org/TR/REC-html40">
          <head>
            <meta name=ProgId content=Excel.Sheet>
            <meta name=Generator content="Microsoft Excel 11">
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <!--[if gte mso 9]>
              <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
              <x:Name>${this.worksheet}</x:Name><x:WorksheetOptions>
              <x:DisplayGridlines/>
              </x:WorksheetOptions>
              </x:ExcelWorksheet></x:ExcelWorksheets>
              </x:ExcelWorkbook></xml>
            <![endif]-->
            <style>br {mso-data-placement: same-cell;}</style>
          </head>
          <body>
            <table>${xlsData}</table>
          </body>
        </html>`;

      return xlsTemp;
    },
    /*
		jsonToCSV
		---------------
		Transform json data into an CSV file.
		*/
    jsonToCSV(data) {
      let _self = this;
      var csvData = [];

      //Header
      const header = this.header || this.$attrs.title;
      if (header) {
        csvData.push(this.parseExtraData(header, "${data}\r\n"));
      }

      //Fields
      for (let key in data[0]) {
        csvData.push(key);
        csvData.push(",");
      }
      csvData.pop();
      csvData.push("\r\n");
      //Data
      data.map(function (item) {
        for (let key in item) {
          let escapedCSV = item[key] + "";
          // Escaped CSV data to string to avoid problems with numbers or other types of values
          // this is controlled by the prop escapeCsv
          if (_self.escapeCsv) {
            escapedCSV = '="' + escapedCSV + '"'; // cast Numbers to string
            if (escapedCSV.match(/[,"\n]/)) {
              escapedCSV = '"' + escapedCSV.replace(/"/g, '""') + '"';
            }
          }
          csvData.push(escapedCSV);
          csvData.push(",");
        }
        csvData.pop();
        csvData.push("\r\n");
      });
      //Footer
      if (this.footer != null) {
        csvData.push(this.parseExtraData(this.footer, "${data}\r\n"));
      }
      return csvData.join("");
    },
    /*
		getProcessedJson
		---------------
		Get only the data to export, if no fields are set return all the data
		*/
    getProcessedJson(data, header) {
      // if there are no fields key then get the key of data set
      let keys = this.getKeys(data, header);
      let newData = [];
      let _self = this;
      data.map(function (item) {
        let newItem = {};
        for (let label in keys) {
          let property = keys[label];
          newItem[label] = _self.getValue(property, item);
        }
        newData.push(newItem);
      });

      return newData;
    },
    getKeys(data, header) {
      if (header) {
        return header;
      }

      let keys = {};
      for (let key in data[0]) {
        keys[key] = key;
      }
      return keys;
    },
    /*
		parseExtraData
		---------------
		Parse title and footer attribute to the csv format
		*/
    parseExtraData(extraData, format) {
      let parseData = "";
      if (Array.isArray(extraData)) {
        for (var i = 0; i < extraData.length; i++) {
          if (extraData[i])
            parseData += format.replace("${data}", extraData[i]);
        }
      } else {
        parseData += format.replace("${data}", extraData);
      }
      return parseData;
    },

    getValue(key, item) {
      const field = typeof key !== "object" ? key : key.field;
      let indexes = typeof field !== "string" ? [] : field.split(".");
      let value = this.defaultValue;

      if (!field) value = item;
      else if (indexes.length > 1)
        value = this.getValueFromNestedItem(item, indexes);
      else value = this.parseValue(item[field]);

      if (Object.prototype.hasOwnProperty.call(key, "callback"))
        value = this.getValueFromCallback(value, key.callback);

      return value;
    },

    /*
    convert values with newline \n characters into <br/>
    */
    valueReformattedForMultilines(value) {
      if (typeof value == "string") return value.replace(/\n/gi, "<br/>");
      else return value;
    },
    preprocessLongNum(value) {
      if (this.stringifyLongNum) {
        if (String(value).startsWith("0x")) {
          return value;
        }
        if (!isNaN(value) && value != "") {
          if (value > 99999999999 || value < 0.0000000000001) {
            return '="' + value + '"';
          }
        }
      }
      return value;
    },
    getValueFromNestedItem(item, indexes) {
      let nestedItem = item;
      for (let index of indexes) {
        if (nestedItem) nestedItem = nestedItem[index];
      }
      return this.parseValue(nestedItem);
    },

    getValueFromCallback(item, callback) {
      if (typeof callback !== "function") return this.defaultValue;
      const value = callback(item);
      return this.parseValue(value);
    },
    parseValue(value) {
      return value || value === 0 || typeof value === "boolean"
        ? value
        : this.defaultValue;
    },
    base64ToBlob(data, mime) {
      let base64 = window.btoa(window.unescape(encodeURIComponent(data)));
      let bstr = atob(base64);
      let n = bstr.length;
      let u8arr = new Uint8ClampedArray(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  }, // end methods
};
</script>
