import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class SettingModel extends BaseModel {

    constructor() {
        super({ 
                name: "setting",
                tableName: 'setting', 
                label: "Cài đặt", 
                display_key: 'code', 
                default_order: { key: 'id', direction: 'asc' } }, 
            {
            id: { 
                name: 'id', 
                type: "bigint", 
                label: 'ID', 
                primaryKey: true, 
                edit: { 
                    add: false, 
                    edit: false 
                }, 
                display: { 
                    list: false, 
                    add: false, 
                    edit: false 
                } 
            },
            code : { 
                name: 'code', 
                type: "String", 
                label: 'Code', 
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            value : { 
                name: 'value', 
                type: "number", 
                label: 'Giá trị', 
                sortable: true,
            },
        },)
    }

}