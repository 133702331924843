import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class GroupUserModel extends BaseModel {

    constructor() {
        super({ 
                name: "group_user",
                tableName: 'group_user',
                label: "Danh Sách Admin"
            },
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
                uid: {
                name: 'uid',
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                group_id: {
                    name: 'group_id',
                    type: "Bigint",
                    label: 'Tên',
                    display: false
                },
        },)
    }

}