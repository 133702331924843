import gql from "graphql-tag";
export default class BaseService {
  constructor(model) {
    this.model = model;
  }
  getModel() {
    return this.model;
  }
  countDataQuery() {
    var stringParam = '', stringWhere = '', fields = '', aggregate = '';
    aggregate = '_aggregate';
    fields =  '{ aggregate{ count } }';
    stringParam = '($where_key: ' + this.model.tableName + '_bool_exp)';
    stringWhere = '(where: $where_key)';
    var query = `query get${this.model.tableName}List${stringParam} {
      ${this.model.tableName}${aggregate}${stringWhere}
      ${fields}
    }`;
    //console.log('query count', query);
    return gql(query);
  }
  getListQuery(params = null) {
    var stringParam = '', stringWhere = '', fields = '', aggregate = '';
    if (params !== null && params['fields'] !== undefined && params['fields'] !== '') {
      fields = '{' + params['fields'] + '}';
    } else {
      //console.log('let return_fields = this.getReturnFields();');
      let return_fields = this.getReturnFields();
      fields = return_fields.mainTable
    }
    if (params !== null && params['aggregate'] !== undefined && params['aggregate'] !== '') {
      aggregate = '_aggregate';
      fields = params['aggregate']
    }
    stringParam = '($where_key: ' + this.model.tableName + '_bool_exp, $orderBy: [' + this.model.tableName + '_order_by!], $offset: Int, $limit: Int)';
    stringWhere = '(where: $where_key, order_by: $orderBy, offset: $offset, limit: $limit)';
    var query = `query get${this.model.tableName}List${stringParam} {
      ${this.model.tableName}${aggregate}${stringWhere}
      ${fields}
    }`;
    //  console.log('query', query);
    return gql(query);
  }
  updateDataQuery() {
    let return_fields = this.getReturnFields();
    var query = `mutation update${this.model.tableName}($where_key: ${this.model.tableName}_bool_exp!, $data_update: ${this.model.tableName}_set_input) {
      update_${this.model.tableName}(where: $where_key, _set: $data_update) {
        affected_rows
        returning ${return_fields.mainTable}
      }
    }`;
    // console.log('query', query);
    return gql(query);
  }
  insertDataQuery() {
    var return_fields = this.getReturnFields();
    var query = `mutation insert${this.model.tableName}($objects: [${this.model.tableName}_insert_input!]!) {
      insert_${this.model.tableName}(objects: $objects) {
        affected_rows
        returning ${return_fields.mainTable}
      }
    }`;
    // console.log('insert', query);
    return gql(query);
  }
  deleteDataQuery() {
    var query = `mutation delete${this.model.tableName}($where_key: ${this.model.tableName}_bool_exp!) {
      delete_${this.model.tableName}(where: $where_key) {
        affected_rows
      }
    }`;
    return gql(query);
  }
  getReturnFields() {
    var return_fields = [
      "{",
      ...this.model.getFieldList(),
      ... this.model.getFieldListRelation(),
      "}"
    ].filter(function(e){return e}).join(" ");
    return { mainTable: return_fields};
  }
  getByMyIdQuery(params = null) {
    var stringWhere = '', fields = '', variablesType ='' ,stringVariablesType = '',WherevariablesType ='';
    if (params !== null && params['fields'] !== undefined && params['fields'] !== '') {
      fields = '{' + params['fields'] + '}';
    } else {
      let return_fields = this.getReturnFields();
      fields = return_fields.mainTable
    }
    if(params !== null && params['where'] !== undefined && params['variawhereblesType'] !== ''){
      WherevariablesType = params['where'];
      stringWhere = `(where: {${WherevariablesType}})`
    }
    if(params !== null && params['variablesType'] !== undefined && params['variablesType'] !== ''){
      variablesType = params['variablesType'];
      stringVariablesType = `(${variablesType})`
    }
    
    var query = `query MyQuery${stringVariablesType} {
      ${this.model.tableName}${stringWhere}
      ${fields}
    }`;
    return gql(query);
  }
}
