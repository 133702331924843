<template>
  <router-view />
</template>

<script>
require("regenerator-runtime");
export default {
  data() {
    return {
      loadComplete: false
    }
  },
  watch: {
    $route() {
      this.onLoadingPage()
    }
  },
  methods: {
    onLoadingPage() {
      this.loadComplete = false
      if (document.readyState === 'complete') {
        this.loadComplete = true
      }
    }
  }
}
</script>

<style lang="scss">
@import "./App.scss";
</style>
