import moment from "moment-timezone";
export default class BaseModel {
  constructor(info, fieldsList) {
    this.tableName = info.tableName;
    this.label = info.label;
    this.display_key = info.display_key;
    this.models = info.models ? info.models : '';
    this.params = info.params ? info.params : '';
    this.callback = info.callback ? info.callback : '';
    this.fields = fieldsList;
    this.arrayField = [];
    this.arrayFieldComputed = [];
    this.arrayFieldRelation = [];
    this.arrayFieldName = [];
    this.displayFields = [];
    for (const key in fieldsList) {
      if (Object.prototype.hasOwnProperty.call(fieldsList, key)) {
        const field = fieldsList[key];
        field.key = key;
        if (field.primaryKey == true) {
          this.key = field;
        }
        if (field.computed) {
          this.arrayFieldComputed.push(field);
        } else {
          if (field.refs !== undefined) {
            this.arrayFieldRelation.push(field.refs);
          } else this.arrayFieldName.push(key);
          this.arrayField.push(field);
        }
        if (field.display == undefined  || (field.display && field.display.list == false)) {
          this.displayFields.push(field);
        }
      }
    }
  }
  getField(fieldName) {
    var field = this.fields[fieldName];
    if (field) {
      if (!field.label) {
        field.label = fieldName;
      }
    }
    return field;
  }
  getFieldListRelation() {
    return this.arrayFieldRelation;
  }
  getFieldList() {
    return this.arrayFieldName;
  }
  getDisplayFields() {
    return this.displayFields;
  }
  processData(data) {
    for (let j = 0; j < data.length; j++) {
      var row = data[j];
      for (let i = 0; i < this.arrayField.length; i++) {
        let field = this.arrayField[i];
        if (field.type == "Date") {
          if (row[field.key]) {
            row[field.key] = moment
              .utc(row[field.key])
              .clone()
              .tz("Asia/Ho_Chi_Minh")
              .toDate();
          }
        }
      }
      for (let i = 0; i < this.arrayFieldComputed.length; i++) {
        var comfield = this.arrayFieldComputed[i];
        comfield.computed(row, j);
      }
    }
  }
}
