import axios from "axios";
import configJson from '../../config';

var envConfig = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') ? process.env.NODE_ENV:'development';
const baseUrl = configJson[envConfig].base_url_api;

export  default  axios.create({
    baseURL:baseUrl,
    withCredentials: false,
    header: {}
})